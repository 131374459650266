
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getStoredUserData, dateFormat, dateFormatWithTime, getQueryString, getRequestStatus, formatNumber, getUserSession, groupByArray, isAuthenticated, canApprove, canAuthorize, canEdit, isRequestor, canViewRequest } from "../../utils/utils";
import { ApiClient } from "../../utils/apiclient";
import { IMaintenanceRequest, IRequest, RequestStatus } from "../../utils/types";
import Sidebar from "../../components/sidebar";
import NotificationView from "../../components/notification_view";
import Image from "../../components/image";
import { MdClose } from "react-icons/md";
import { CareMaintenanceImage, CareVehicleImage } from "../../components/image-assets";
import RequestStatusBadge from "../../components/request_status";
import { RequestService } from "../../utils/services/request.service";
import { REQUEST_TYPES, REQUESTS_STATUS, REQUESTS_STATUS_ID, SERVICE_TYPES } from "../../utils/constants";
import ChargingInstructionsChart from "../../components/charging-instructions-chart";
import Spinner from "../../components/spinner";
import { toast, ToastContainer } from "react-toastify";
import MaintenanceRequestCompleteForm from "./service_request_complete_form";
import TopStrip from "../../components/top_strip";

const ServiceRequestView = (props: any) => {
    const userData = getStoredUserData()
    const userSession = getUserSession()
    const client = new ApiClient(userSession?.accessToken);
    const requestService = new RequestService(client)
    const params = useParams();
    const query: URLSearchParams = useSearchParams()[0];
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const navigate = useNavigate()
    const location = useLocation();

    const requestItem = location.state?.request;

    const [loading, setLoading] = useState<boolean>(false)
    const [request, setRequest] = useState<IMaintenanceRequest>(requestItem)
    const [history, setHistory] = useState<any[]>([])
    const [totalDistance, setTotalDistance] = useState<number>(0)
    const [totalCost, setTotalCost] = useState<number>(0)
    const [comment, setComment] = useState<string>()
    const [open, setOpen] = useState<boolean>(false)
    const [lastRequest, setLastRequest] = useState<IMaintenanceRequest>()

    const handleCancel = () => {
        navigate('/requests', { replace: false })
    }
    const rejectRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                remark: comment,
                attended_by: userData.id,
                closed_by: userData.id,
                nameOfAttender: `${userData.fname} ${userData.lname}`
            }
            setLoading(true)
            try {
                const axios = await requestService.processRequest(data, userData, false)
                toast.info(axios.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => { navigate(-1) }, 5000)
            }
            catch (e: any) {
                toast.error("Something went wrong, please contact support", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            finally {
                getRequestDetails(request.id, request.type)
                setLoading(false)
            }
        }

    }
    const approveRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                nameOfApprover: `${userData.fname} ${userData.lname}`,
                status: REQUESTS_STATUS_ID.APPROVED,
                attender: userData?.id,
                id: request.id,
                type: request.type,
                vehicle: request.vehicle,
                remark: comment
            }
            try {
                setLoading(true)
                const req = await requestService.processRequest(data, userData, true);
                toast.info(req.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => { navigate(-1) }, 5000)
            }
            catch (e: any) {
                toast.error("Something went wrong, please contact support", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            finally {
                setLoading(false)

            }
        }

    }
    const getComment = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value.trim();
        if (text && text.length > 0) setComment(text)
    }
    const authorizeRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                nameOfAttender: `${userData.fname} ${userData.lname}`,
                status: REQUESTS_STATUS_ID.AUTHORIZED,
                attender: userData.id,
                id: request.id,
                type: request.type,
                remark1: comment,
                cost: request.cost
            }
            try {
                setLoading(true)
                const req = await requestService.authorizeRequest(data, userData, true);
                console.log("🚀 ~ approveRequest ~ req:", req)
                toast.info(req.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => {
                    navigate(-1)
                }, 5000)

            }
            catch (e: any) {
                console.log("🚀 ~ approveRequest ~ e:", e)
                toast.error("Something went wrong, please contact support", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
            finally {
                setLoading(false)
            }
        }

    }
    const getRequestDetails = async (reqId: number, reqType: number) => {
        setLoading(true)
        // const type = getRequestTypeIdFromText(reqType)
        const axios = await requestService.getRequestDetails(reqId, reqType);

        if (axios.status == 200 || axios.status == 201) {
            const req = axios.data.request;
            setRequest(req);
            if (req.status < 3) getVehicleHistory(req.vehicle, reqType, req.id)
        }
        setLoading(false)

    }

    const getVehicleHistory = async (vehicle: string, requestType: number, requestId?: number) => {
        console.log("🚀 ~ getVehicleHistory ~ requestType:", requestType)
        const axios = await client.getVehicleHistory(vehicle, requestType, requestId);
        const history = axios.data.history;
        if (typeof history == 'string') {
            setHistory([]);
        }
        else prepareHistoryData(history)
    }
    const prepareHistoryData = (history: any[]) => {
        if (history && history.length > 0) {
            let newHistory = groupByArray(history, "fundcode");
            let mappedHistory = newHistory.map((h: any) => {
                let hist = {
                    ...h,
                    distance: h.values.reduce(
                        (a: number, b: any) => a + b.distance,
                        0
                    ),
                    project: h.values[0].project
                }
                return hist;
            });
            let totalDistance = mappedHistory.reduce((a: number, b: any) => a + b.distance, 0);
            setHistory(mappedHistory.map((h: any) => {
                return {
                    ...h,
                    ratio: totalDistance > 0 ? (h.distance / totalDistance).toFixed(3) : 0
                }
            }));
            setTotalDistance(totalDistance)
        } else {
            setHistory([]);
            setTotalDistance(0)
        }
    }
    const updateInfo = async (data: any) => {
        setLoading(true)
        const body = {
            ...request,
            a_cost: data.cost,
            a_mileage: data.mileage,
            closed_by: userData?.id,
            comment: data.comment,
            date_processed: Date.parse(data.date) / 1000
        }
        try {
            const axios = await requestService.completeMaintenanceRequest(body)
            console.log("🚀 ~ updateAmountAndPrice ~ axios:", axios)
            setLoading(false)
            if (axios.status == 200 || axios.status == 201) {
                toast.info(axios.data.response, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
                setTimeout(() => {
                    navigate('/requests', { replace: true })
                }, 5000)

            }
        }
        catch (e: any) {
            console.log("🚀 ~ updateInfo ~ e:", e)
            toast.error("An error occurred. Please contact support", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
    }
    const getLastRequest = async (req: IMaintenanceRequest) => {
        const lastRequestRes = await requestService.getLastRequest(req.vehicle, req.type);
        const lastReq = lastRequestRes.data[0]
        if (lastReq && typeof lastReq !== 'string') {
            console.log("🚀 ~ getLastRequest ~ lastReq:", lastReq.a_mileage)
            setLastRequest(lastRequestRes.data[0]);
            setTotalDistance(request.a_mileage - lastReq.a_mileage)
        }
    }
    const organizeInstructions = () => {
        console.log("🚀 ~ ServiceRequestView ~ any:", lastRequest)

        if (request.cost_distribution) {
            const ratios = request.cost_distribution.split(",").map((r) => Number(r));
            const fundcodes = request.fundcode.split(',');
            const projects = request.project.split(",")
            const history = fundcodes.map((f, i) => {
                return {
                    key: f,
                    project: projects[i],
                    ratio: ratios[i],
                    distance: totalDistance * ratios[i],
                    const: totalCost * ratios[1]
                }
            })
            console.log("🚀 ~ history ~ history:", history)
            setHistory(history)
        }

    }
    useEffect(() => {
        if (isAuthenticated()) {
            if (!request) {
                const id = Number(params.reqId);
                const type = Number(query.get('t'));
                getRequestDetails(id, type)

            }
            else {
                console.log("🚀 ~ useEffect ~ request:", request)
                getLastRequest(request)
                if (request.status == REQUESTS_STATUS_ID.CLOSED && [REQUEST_TYPES.FULL_SERVICE, REQUEST_TYPES.NORMAL_SERVICE].includes(request.type)) {
                    organizeInstructions()
                }
                else getVehicleHistory(request.vehicle, request.type, request.id)
            }

        }
        else {
            navigate('/login', { state: { targetPath: target } })
        }


    }, [])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <ToastContainer />
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            {open ? <MaintenanceRequestCompleteForm onSubmit={updateInfo} state={open} onClose={() => { setOpen(false) }} data={request} comment={comment} /> : null}
            {request && canViewRequest(userData, request) ?
                <div className="md:w-9/12 mx-auto space-y-4 mt-2">
                    {loading ? <Spinner className="spinner-md mt-6" /> :
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between p2-4">
                                <p className="text-2xl font-bold">Service Request</p>
                            </div>
                            <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                                <div className="p-4 w-full md:w-4/12 text-center">
                                    <Image src={CareMaintenanceImage} className="w-6/12 mx-auto" />
                                    <p className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getRequestStatus(request.status)} /></p>
                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Date Requested</label>
                                        <p className="text-1xl">{`${dateFormat(request.date_created)}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Vehicle </label>
                                        <p className="text-1xl">{`${request.vehicle}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Supplier</label>
                                        <p className="text-1xl">{`${request.supplier_name}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Parts</label>
                                        <p className="text-1xl">{`${request.parts}`}</p>
                                    </div>
                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Requestor</label>
                                        <p className="text-1xl">{`${request.nameOfRequestor}`}</p>
                                    </div>


                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Mileage (Km)</label>
                                        <p className="text-1xl">{formatNumber(request.mileage)}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Cost (Tsh)</label>
                                        <p className="text-1xl">{`${formatNumber(request.cost)}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Service Type </label>
                                        <p className="text-1xl">{SERVICE_TYPES.find((t) => t.type == request.type)?.text}</p>
                                    </div>

                                </div>
                                <div className="m-4 flex-col justify-between items-end h-full">
                                    <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/requests') }} />

                                </div>
                            </div>
                            <fieldset className="w-full mt-5 py-4 px-2 border mx-auto flex-col justify-start items-start">
                                <legend className="mx-4 text-start px-4">Charging Instructions</legend>
                                <div className="w-full flex justify-start items-start mt-5 py-4 px-2 flex-wrap border mx-auto">
                                    <div className="text-start mb-4 w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Project: </label>
                                        <p className="text-1xl">{request.project.split(",").map((p:string)=>{
                                            return <p key={p}>{p}</p>
                                        })
                                        }</p>
                                    </div>
                                    <div className="text-start mb-4 w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Fund Code: </label>
                                        <p className="text-1xl">{request.fundcode.split(",").map((f:string)=>{
                                            return <p key={f}>{f}</p>
                                        })
                                        }</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Account and source  </label>
                                        <p className="text-1xl">{`${request.account}, ${request.source}`}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Category/Activity: </label>
                                        <p className="text-1xl">{`${request.category}/ ${request.activity}`}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approver: </label>
                                        <p className="text-1xl">{request.nameOfDefaultApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Admin: </label>
                                        <p className="text-1xl">{`${request.nameOfAdmin}`}</p>
                                    </div>
                                    {request.statusText == REQUESTS_STATUS.APPROVED ?
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Approved by: </label>
                                            <p className="text-1xl">{request.nameOfApprover}</p>
                                        </div> : null}
                                    {request.statusText == REQUESTS_STATUS.AUTHORIZED ? <>
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Approved by: </label>
                                            <p className="text-1xl">{request.nameOfApprover}</p>
                                        </div>
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Authorized by: </label>
                                            <p className="text-1xl">{request.nameOfAttender}</p>
                                        </div></> : null}
                                    {request.statusText == REQUESTS_STATUS.REJECTED ?
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Rejected by: </label>
                                            <p className="text-1xl">{request.nameOfAttender}</p>
                                        </div> : null}
                                    {request.statusText == REQUESTS_STATUS.CLOSED ? <>
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Approved by: </label>
                                            <p className="text-1xl">{request.nameOfApprover}</p>
                                        </div>
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Authorized by: </label>
                                            <p className="text-1xl">{request.nameOfAttender}</p>
                                        </div>
                                        <div className="text-start mb-4  w-6/12 md:w-3/12">
                                            <label className="text-gray-500">Closed by: </label>
                                            <p className="text-1xl">{request.nameOfCloser}</p>
                                        </div></> : null}
                                    {request.status == REQUESTS_STATUS_ID.APPROVED && request.comment ? <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Comment: </label>
                                        <p className="text-1xl">{request.comment}</p>
                                    </div> : null}
                                </div>
                                <div className="w-full mx-auto">
                                {(request.type == 2 || request.type == 3) && history.length > 0 ? <ChargingInstructionsChart totalCost={request.cost} totalDistance={totalDistance} history={history} /> : null}
                                </div>
                            </fieldset>
                            {/* <div className="w-full flex justify-start items-start mt-5 py-4 px-2 flex-wrap border mx-auto">
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Approver</label>
                                    <p className="text-1xl">{`${request.nameOfDefaultApprover}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Admin</label>
                                    <p className="text-1xl">{`${request.nameOfAdmin}`}</p>
                                </div>

                                {request.status == REQUESTS_STATUS_ID.APPROVED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div> : null}
                                {request.status == REQUESTS_STATUS_ID.AUTHORIZED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div></> : null}
                                {request.status == REQUESTS_STATUS_ID.REJECTED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Rejected by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div> : null}
                                {request.status == REQUESTS_STATUS_ID.CLOSED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Closed by: </label>
                                        <p className="text-1xl">{request.nameOfCloser}</p>
                                    </div>

                                </> : null}
                            </div> */}

                            <div className="w-full flex-column items-start justify-center mt-5 p-4 border mx-auto">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Remarks</label>
                                    {request.comment ? <p className="text-sm">{request.nameOfRequestor}: {request.comment ? request.comment : 'None'}</p> : null}
                                    {request.remark ? <p className="text-sm">{request.nameOfApprover || request.nameOfAttender}: {request.remark ? request.remark : 'None'}</p> : null}
                                    {request.remark1 ? <p className="text-sm">{request.nameOfAttender}: {request.remark1 ? request.remark1 : 'None'}</p> : null}
                                </div>
                                {(canApprove(userData, request) || canAuthorize(userData, request) || canEdit(userData, request)) && request.status in [REQUESTS_STATUS_ID.PENDING, REQUESTS_STATUS_ID.APPROVED, REQUESTS_STATUS_ID.AUTHORIZED] ?
                                    <input type="text" name="comment" className="form-control p-2" placeholder="write your comment or remarks" onChange={getComment} /> : null
                                }
                                <div className="flex justify-end items-center space-x-5 mt-1">

                                    {canApprove(userData, request) && request.statusText == REQUESTS_STATUS.PENDING ? <button className="bg-green-700" onClick={approveRequest}>Approve</button> : null}
                                    {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <button className="bg-green-700" onClick={authorizeRequest}>Authorize</button> : null}
                                    {(isRequestor(userData, request) || canApprove(userData, request)) && request.statusText == REQUESTS_STATUS.AUTHORIZED ? <button className="bg-green-700" onClick={() => { setOpen(true) }}>Complete</button> : null}
                                    {canEdit(userData, request) ? <button className="bg-red-600" onClick={rejectRequest}>{userData?.id == request.requestor ? 'Cancel' : 'Reject'}</button> : null}

                                </div>
                            </div>
                        </div>}
                </div> : <p className="text-center text-2xl mx-auto">You cannot view this data</p>}

        </main></>);
}
export default ServiceRequestView;