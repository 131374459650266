
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getStoredUserData, dateFormat, getQueryString, getRequestStatus, canApprove, canAuthorize, canEdit, canViewRequest, getUserSession, isAuthenticated, isRequestor, formatNumber } from "../../utils/utils";
import { MdClose, MdEdit } from "react-icons/md";
import { CareFuelImage } from "../../components/image-assets";
import NotificationView from "../../components/notification_view";
import RequestStatusBadge from "../../components/request_status";
import Sidebar from "../../components/sidebar";
import { ApiClient } from "../../utils/apiclient";
import { IFuelRequest } from "../../utils/types";
import Image from "../../components/image";
import Spinner from "../../components/spinner";
import { REQUESTS_STATUS, REQUESTS_STATUS_ID } from "../../utils/constants";
import { RequestService } from "../../utils/services/request.service";
import FuelRequestCompleteForm from "./fuel_request_complete_form";
import TopStrip from "../../components/top_strip";

const FuelRequestView = (props: any) => {
    const userData = getStoredUserData()
    const userSession = getUserSession()
    const client = new ApiClient(userSession?.accessToken);
    const requestService = new RequestService(client)
    const params = useParams();
    const query: URLSearchParams = useSearchParams()[0];
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const navigate = useNavigate()
    const location = useLocation();

    const requestItem = location.state?.request;

    const [loading, setLoading] = useState<boolean>(false)
    const [request, setRequest] = useState<IFuelRequest>(requestItem)
    const [lastRequest, setLastRequest] = useState<IFuelRequest>()
    const [comment, setComment] = useState<string>()
    const [amount, setAmount] = useState<number>(request?.amount)
    const [price, setPrice] = useState<string>(request?.price)
    const [open, setOpen] = useState<boolean>(false)


    const getRequestDetails = async (reqId: number, reqType: number) => {
        setLoading(true)
        const axios = await requestService.getRequestDetails(reqId, reqType);
        if (axios.status == 200) {
            const req = axios.data.request
            setRequest(req);

            getLastRequest(req)
        }
        setLoading(false)
    }
    const getLastRequest = async (request: IFuelRequest) => {
        const lastRequest = await requestService.getLastRequestOnVehicle(request.vehicle, request.type, request.id);
        if (lastRequest.status == 200) {
            if (lastRequest.data.request.length > 0) setLastRequest(lastRequest.data.request[0])
        }
    }
    const showNotifications = () => {

    }
    const getComment = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value.trim();
        if (text && text.length > 0) setComment(text)
    }
    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const amount = (e.currentTarget.value && e.currentTarget.value.length > 0 && Number(e.currentTarget.value) > 0) ? Number(e.currentTarget.value.trim()) : request.amount;
        setAmount(amount)
    }
    const handleChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        const price = (e.currentTarget.value && e.currentTarget.value.length > 0 && Number(e.currentTarget.value) > 0) ? e.currentTarget.value.trim() : request.price;
        setPrice(price)
    }
    const rejectRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                remark: comment,
                attended_by: userData.id,
                closed_by: userData.id,
                nameOfAttender: `${userData.fname} ${userData.lname}`
            }
            setLoading(true)
            try {
                const axios = await requestService.processRequest(data, userData, false)

            }
            catch (e: any) {
                console.log("🚀 ~ sendApproval ~ e:", e)
                alert("Something went wrong")
            }
            finally {
                getRequestDetails(request.id, request.type)
                setLoading(false)
            }
        }

    }
    const generateFuelOrder = () => {
        const data = {
            ...request,
            last_request: lastRequest
        }
        navigate('/requests/fuel/order', { state: { data: data } })
    }
    const approveRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                nameOfApprover: `${userData.fname} ${userData.lname}`,
                status: REQUESTS_STATUS_ID.APPROVED,
                attender: userData?.id,
                id: request.id,
                type: request.type,
                vehicle: request.vehicle,
                remark: comment
            }
            try {
                setLoading(true)
                const req = await requestService.processRequest(data, userData, true);
                console.log("🚀 ~ approveRequest ~ req:", req)
            }
            catch (e: any) {
                console.log("🚀 ~ approveRequest ~ e:", e)

            }
            finally {
                setLoading(false)
            }
        }

    }
    const authorizeRequest = async () => {
        if (userData) {
            const data = {
                ...request,
                nameOfAttender: `${userData.fname} ${userData.lname}`,
                status: REQUESTS_STATUS_ID.AUTHORIZED,
                attender: userData.id,
                id: request.id,
                type: request.type,
                remark1: comment,
                amount: amount ? amount : request.amount,
                price: price ? price : request.price
            }
            try {
                setLoading(true)
                const req = await requestService.authorizeRequest(data, userData, true);
                console.log("🚀 ~ approveRequest ~ req:", req)

            }
            catch (e: any) {
                console.log("🚀 ~ approveRequest ~ e:", e)

            }
            finally {
                setLoading(false)
            }
        }

    }
    const updateAmountAndPrice = async (data: any) => {
        setLoading(true)
        const body = {
            ...request,
            a_amount: data.amount,
            a_price: data.price,
            a_mileage: data.mileage,
            closed_by: userData?.id,
            comment: data.comment,
            date_processed: Date.parse(data.date) / 1000
        }
        const axios = await requestService.completeFuelRequest(body)
        console.log("🚀 ~ updateAmountAndPrice ~ axios:", axios)
        setLoading(false)
        if (axios.status in [200, 201]) {
            navigate('/requests', { replace: true })
        }

    }
    useEffect(() => {
        if (isAuthenticated()) {
            if (!request) {
                const id = Number(params.reqId);
                const type = Number(query.get('t'));
                getRequestDetails(id, type)
            }
            else getLastRequest(request);
        }
        else navigate('/login', { state: { targetPath: target } });
    }, [request])
    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={[].length} user={userData} active={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            {open ? <FuelRequestCompleteForm onSubmit={updateAmountAndPrice} state={open} onClose={() => { setOpen(false) }} data={request} /> : null}
            {request && canViewRequest(userData, request) ?
                <div className="md:w-9/12 mx-auto space-y-2 mt-2">
                    {loading ? <Spinner className="spinner-md mt-6" /> :
                        <div className="w-full">
                            <div className="w-full flex items-center justify-between p2-4">
                                <p className="text-2xl font-bold">Fuel Request</p>
                            </div>
                            <div className="w-full flex justify-between items-start py-6 mx-auto border mt-6 h-full">
                                <div className="p-4 w-full md:w-4/12 text-center">
                                    <Image src={CareFuelImage} className="w-6/12 mx-auto" />
                                    <div className="m-4 text-1xl mx-auto "><RequestStatusBadge status={getRequestStatus(request.status)} /></div>
                                </div>
                                <div className="ms-5 md:w-6/12">
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Date Created: </label>
                                        <p className="text-1xl">{dateFormat(request.date_created)}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Requestor's Name: </label>
                                        <p className="text-1xl">{`${request.nameOfRequestor}`}</p>
                                    </div>

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Mileage (Km): </label>
                                        <p className="text-1xl">{request.a_mileage ? request.a_mileage : request.mileage}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Distance Covered (Km): </label>
                                        {request.a_mileage ? <p className="text-1xl">{`${request.a_mileage - (lastRequest ? lastRequest.a_mileage : request.mileage)}`}</p>:<p className="text-1xl">{`${request.mileage - (lastRequest ? lastRequest.a_mileage : request.mileage)}`}</p>}
                                        
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Fuel Quantity (Lts): </label>
                                        {canAuthorize(userData, request) && request.status == REQUESTS_STATUS_ID.APPROVED ? <input className="form-control md:w-8/12" id="a_amount" name="a_amount" defaultValue={request.amount} onChange={handleChangeAmount} /> : <p className="text-1xl">{formatNumber(request.amount)}</p>}
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Cost of Fuel (TSH): </label>
                                        <p className="text-1xl">{formatNumber(request.amount * Number(request.price))}</p>
                                    </div>
                                </div>
                                <div className="ms-5 md:w-6/12">

                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Refuel Date: </label>
                                        <p className="text-1xl">{dateFormat(request.start_time)}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Vehilce: </label>
                                        <p className="text-1xl">{`${request.vehicle}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Last Refuel Mileage (Km): </label>
                                        {lastRequest ? <p className="text-1xl">{lastRequest.a_mileage ? lastRequest.a_mileage : lastRequest.mileage}</p>:'N/A'}
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Supplier: </label>
                                        <p className="text-1xl">{`${request.supplier_name}`}</p>
                                    </div>
                                    <div className="text-start mb-4">
                                        <label className="text-gray-500">Price (TSH): </label>
                                        {canAuthorize(userData, request) && request.status == REQUESTS_STATUS_ID.APPROVED ? <input className="form-control md:w-8/12" id="a_price" name="a_price" defaultValue={request.price} onChange={handleChangePrice} /> : <p className="text-1xl">{formatNumber(request.price)}</p>}
                                    </div>

                                    {/* <div className="text-start mb-4">

                                        {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <>
                                            <label className="text-gray-500">Assign a Driver: </label>
                                            <select name="ass_driver" id="ass_driver" className="form-control md:w-9/12 p-2" onChange={handleDriverSelect}><option value='0'>--select--</option>
                                                {drivers.map((driver: User) => {
                                                    return <option key={driver.id} value={driver.id}>{`${driver.fname} ${driver.lname}`}</option>
                                                })}
                                            </select></>
                                            : <><label className="text-gray-500">Assigned Driver: </label>
                                                <p className="text-1xl">{`${request.driver ? request.nameOfDriver : 'N/A'}`}</p>
                                            </>}
                                    </div> */}

                                </div>
                                <div className="m-4 flex-col justify-between items-end h-full space-y-5">
                                    <MdClose className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/requests') }} />
                                    {/* {canEdit(userData, request) ? <MdEdit className="text-2xl cursor-pointer hover:text-primary" onClick={() => { navigate('/requests/fuel/edit', { state: { request: request } }) }} /> : null} */}
                                </div>
                            </div>
                            <div className="w-full flex justify-start items-start mt-5 py-4 px-2 flex-wrap border mx-auto">
                                <div className="text-start mb-4 w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Project: </label>
                                    <p className="text-1xl">{`${request.project}`}</p>
                                </div>
                                <div className="text-start mb-4 w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Fund Code: </label>
                                    <p className="text-1xl">{`${request.fundcode}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Account and source  </label>
                                    <p className="text-1xl">{`${request.account}, ${request.source}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Category/Activity: </label>
                                    <p className="text-1xl">{`${request.category}/ ${request.activity}`}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Approver: </label>
                                    <p className="text-1xl">{request.nameOfDefaultApprover}</p>
                                </div>
                                <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Admin: </label>
                                    <p className="text-1xl">{`${request.nameOfAdmin}`}</p>
                                </div>
                                {request.statusText == REQUESTS_STATUS.APPROVED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div> : null}
                                {request.statusText == REQUESTS_STATUS.AUTHORIZED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div></> : null}
                                {request.statusText == REQUESTS_STATUS.REJECTED ?
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Rejected by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div> : null}
                                {request.statusText == REQUESTS_STATUS.CLOSED ? <>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Approved by: </label>
                                        <p className="text-1xl">{request.nameOfApprover}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Authorized by: </label>
                                        <p className="text-1xl">{request.nameOfAttender}</p>
                                    </div>
                                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                                        <label className="text-gray-500">Closed by: </label>
                                        <p className="text-1xl">{request.nameOfCloser}</p>
                                    </div></> : null}
                                {request.status == REQUESTS_STATUS_ID.APPROVED && request.comment ? <div className="text-start mb-4  w-6/12 md:w-3/12">
                                    <label className="text-gray-500">Comment: </label>
                                    <p className="text-1xl">{request.comment}</p>
                                </div> : null}
                            </div>

                            <div className="w-full flex-column items-start justify-center mt-5 p-4 border mx-auto">
                                <div className="text-start mb-4">
                                    <label className="text-gray-500">Remarks </label>
                                    {request.comment ? <p className="text-sm">{request.nameOfRequestor}: {request.comment ? request.comment : 'None'}</p> : null}
                                    {request.remark ? <p className="text-sm">{request.nameOfApprover}: {request.remark ? request.remark : 'None'}</p> : null}
                                    {request.remark1 ? <p className="text-sm">{request.nameOfAttender}: {request.remark1 ? request.remark1 : 'None'}</p> : null}
                                </div>
                                {(canApprove(userData, request) || canAuthorize(userData, request) || canEdit(userData, request)) && request.status in [REQUESTS_STATUS_ID.PENDING, REQUESTS_STATUS_ID.APPROVED, REQUESTS_STATUS_ID.AUTHORIZED] ?
                                    <input type="text" name="comment" className="form-control p-2" placeholder="write your comment or remarks" onChange={getComment} /> : null
                                }
                                <div className="flex justify-end items-center space-x-5 mt-1">

                                    {canApprove(userData, request) && request.statusText == REQUESTS_STATUS.PENDING ? <button className="bg-green-700" onClick={approveRequest}>Approve</button> : null}
                                    {canAuthorize(userData, request) && request.statusText == REQUESTS_STATUS.APPROVED ? <button className="bg-green-700" onClick={authorizeRequest}>Authorize</button> : null}
                                    {(isRequestor(userData, request) || canApprove(userData, request)) && request.statusText == REQUESTS_STATUS.AUTHORIZED ? <button className="bg-green-700" onClick={() => { setOpen(true) }}>Complete</button> : null}
                                    {(isRequestor(userData, request) || canApprove(userData, request)) && request.statusText == REQUESTS_STATUS.AUTHORIZED ? <button className="bg-blue-500" onClick={generateFuelOrder}>Fuel Order</button> : null}
                                    {canEdit(userData, request) ? <button className="bg-red-600" onClick={rejectRequest}>{userData?.id == request.requestor ? 'Cancel' : 'Reject'}</button> : null}

                                </div>
                            </div>

                        </div>}
                </div> : <p className="text-center text-2xl mx-auto">You can not view this data</p>}
        </main>

    </>);
}
export default FuelRequestView;