'use client';
import { useState, useEffect } from "react";
import { getStoredUserData, getQueryString, getUserSession, formatNumber, getRequestTypeText, formatMoney, isAuthenticated } from "../../utils/utils";
import { ApiClient } from "../../utils/apiclient";
import { IPaginationOptions, Trip } from "../../utils/types";
import Sidebar from "../../components/sidebar";
import SummaryCard from "../../components/summary_card";
// const ReactApexChart = dynamic(() => import("react-apexcharts"), { ssr: false })
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";
import TripsTable from "../trips/trips_table";
import { ReportService } from "../../utils/services/report.service";
import PageControl from "../../components/page_control";
import TopStrip from "../../components/top_strip";
import { ApexOptions } from "apexcharts";

const Dashboard = (_props: any) => {
    const userData = getStoredUserData();
    const session = getUserSession();
    const apiClient = new ApiClient(session?.accessToken);
    const reportService = new ReportService(apiClient)
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const navigate = useNavigate()
    const [trips, setTrips] = useState<Trip[]>([])
    const [notifications, setNotifications] = useState<any>({totalCount:0,notifications:[]})
    const [loading, setLoading] = useState<boolean>(false)
    const [reports, setReports] = useState<any>({ cancelledRequests: [], requestCountByType: [], pendingRequests: [], approvedRequests: [], authorizedRequests: [], rejectedRequests: [], closedRequests: [] })
    const [pageInfo, setPageInfo] = useState<any>({ totalPages: 0, totalCount: 0, currentPage: 1, pageSize: 15, hasNext: false, hasPrevious: false });
    const [pageSize, setPageSize] = useState<number>(20)
    const [page, setPage] = useState<number>(1)

   
    const getPreviousPage = async () => {
        setPage(page - 1)
        const incompleteTrips = await loadIncompleteTrips({ page: page - 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })
        setTrips(incompleteTrips.trips)
    }
    const getNextPage = async () => {
        setPage(page + 1);
        const incompleteTrips = await loadIncompleteTrips({ page: page + 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })
        setTrips(incompleteTrips.trips)
    }
    const options:ApexOptions = {
        chart: {
            id: 'apexchart-example',
            
            toolbar: { show: false },
        },
        title: {
                text: 'Number of Requests', style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#263238'
                },
                
            },
        stroke:{
            curve: "smooth",
            width:2
        },
        xaxis: {
            categories: reports?.years
        }
    }

    const series = [{
        name: 'Pending',
        data: reports?.pendingRequests.map((p: any) => p.count)
    }, {
        name: 'Approved',
        data: reports?.approvedRequests.map((p: any) => p.count)
    }, {
        name: 'Authorized',
        data: reports?.authorizedRequests.map((p: any) => p.count)
    }, {
        name: 'Rejected',
        data: reports?.rejectedRequests.map((p: any) => p.count)
    }, {
        name: 'Cancelled',
        data: reports?.cancelledRequests.map((p: any) => p.count)
    }, {
        name: 'Closed',
        data: reports?.closedRequests.map((p: any) => p.count)
    }]

    const options2 = {
        chart: {
            title: 'Just a title',
            toolbar: { show: false }
        },

        labels: reports?.requestCountByType.map((c: any) => c.type == 2 ? 'Maintenance Request' : getRequestTypeText(c.type)),
    }
    const series2 = reports && reports.requestCountByType ? reports?.requestCountByType.map((c: any) => c.count) : []
    //    const series2: ,
    const handlePageSizeChange = async (pageSize: number) => {
        if (pageSize && pageSize >= 10) {
            setPageSize(pageSize);
            setPage(1);

        }
        else {
            setPageSize(10);
            setPage(1);
        }
        const incompleteTrips = await loadIncompleteTrips({ page: 1, take: pageSize })
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious });
        setTrips(incompleteTrips.trips)
    }
    const loadIncompleteTrips = async (options: IPaginationOptions) => {
        const result = await apiClient.getIncompleteTrips(options);
        return result.data;
    }
    const getNotifications = async (userId: number) => {

        let axios = await apiClient.getUnreadNotifications(userId);
        console.log("🚀 ~ getNotifications ~ axios:", axios)
        if (axios.status == 200 || axios.status==201) {
            const notifications = axios.data;
            setNotifications(notifications)
        }
    }
    const showNotifications = () => {
        navigate(`/notifications/${_props.user?.id}`)
    }
    const loadData = async () => {
        setLoading(true)
        const [incompleteTrips, monthlyReports] = await Promise.all([loadIncompleteTrips({ page: page, take: pageSize }), getMonthlyReports()]);
        setPageInfo({ totalPages: incompleteTrips.totalPages, totalCount: incompleteTrips.totalCount, currentPage: incompleteTrips.page, pageSize: incompleteTrips.pageSize, hasNext: incompleteTrips.hasNext, hasPrevious: incompleteTrips.hasPrevious })

        setTrips(incompleteTrips.trips)
        setReports(monthlyReports)
        setLoading(false)
    }
    const getMonthlyReports = async () => {
        try {
            const reports = await reportService.getMonthlySummary()
            if (reports.status == 200) {
                return reports.data
            }
            return null;
        }
        catch (e) {
            console.log("🚀 ~ getMonthlyReports ~ e:", e)
            return null;
        }

    }
    useEffect(() => {
        if(isAuthenticated()){
             if (userData) {
            loadData();
            getNotifications(userData.id);
        }
        }
       
        else {
            navigate('/login', { state: { targetPath: target } })
        }
    }, [])


    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
            <TopStrip user={userData} />
        </div>
        <main className="w-full flex bg-white min-h-screen">
            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={notifications.length} user={userData} active={0} />
            </div>
            {loading ? <Spinner className="spinner-md mt-6" /> :
                <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                    <div className="flex items-center justify-start md:space-x-2 mt-6">
                        <SummaryCard title="Total Requests" value={formatNumber(reports?.requestCount)} bg="orange" color="black-300" />
                        {/* <SummaryCard title="Approval Time" value={secondsToTime(reports?.averageApprovalTime)} bg="blue" color="white-300" /> */}
                        <SummaryCard title="Av Trip per Vehicle" value={`${formatNumber(reports?.tripSummary?.averageVehicleDistance)}Km`} bg="brown" color="white-300" />
                        <SummaryCard title="Maintenance" value={formatMoney(reports?.cost?.totalCost)} bg="yellow" color="black-300" />
                    </div>
                    <div className="w-full border flex items-center">
                        <ReactApexChart className="w-full" type="line" height={400} width={600} series={series} options={options} />
                        <ReactApexChart className="w-full" type="donut" height={300} width={400} options={options2} series={series2} />
                    </div>
                    {trips && trips.length > 0 ?
                        <div className="w-full">
                            <p className="my-4 text-2xl">Incomplete Trips</p>
                            <div className="w-full border-top flex-row flex-start bg-main-color align-items-center ">
                                <PageControl pageSize={pageSize} onNext={getNextPage} onPrevious={getPreviousPage} currentPage={page} totalPages={pageInfo.totalPages} onPageSizeChange={(pageSize: number) => { handlePageSizeChange(pageSize) }} hasNext={pageInfo.hasNext} hasPrevious={pageInfo.hasPrevious} />
                            </div>
                            <TripsTable data={trips} />
                        </div> : <p className="my-4 text-1xl"> No incomplete trips</p>}
                </div>}


        </main>
    </>);

}
export default Dashboard;