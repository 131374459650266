import { useLocation, useNavigate } from "react-router-dom";
import NotificationView from "../../components/notification_view";
import Sidebar from "../../components/sidebar";
import { getQueryString, getStoredUserData, getUserSession, isAdmin, isAuthenticated } from "../../utils/utils";
import { SETTINGS_TABS, USER_POSITION_ID, USER_POSITIONS } from "../../utils/constants";
import Tab from "../../components/tab";
import { ApiClient } from "../../utils/apiclient";
import { useEffect, useState } from "react";
import { Fundcode, IDepartmentDetail, IProjectDetail, ISupplier, IUser } from "../../utils/types";
import DepartmentTable from "./department_table";
import SearchBox from "../../components/search_box";
import FundcodeTable from "./fundcode_table";
import ProjectTable from "./project_table";
import DepartmentForm from "./department_form";
import { ToastContainer, toast } from "react-toastify";
import ProjectForm from "./project_form";
import FundcodeForm from "./fundcode_form";
import SupplierForm from "./supplier_form";
import SupplierTable from "./supplier_table";
import AccountSettings from "./account_settings";
import TopStrip from "../../components/top_strip";

const Settings = (_props: any) => {
    const navigate = useNavigate();
    const location = useLocation()
    const notifications = []
    const userData = getStoredUserData();
    const session = getUserSession();
    const apiClient = new ApiClient(session?.accessToken)
    const target = `${window.location.pathname}${getQueryString(window.location.href)}`
    const tabs = isAdmin(userData) ? SETTINGS_TABS : SETTINGS_TABS.filter(a => a.id == 5)
    const defaultTab = location.state?.defaultTab ? location.state.defaultTab : null
    console.log("🚀 ~ Settings ~ defaultTab:", defaultTab)
    const [departments, setDepartments] = useState<IDepartmentDetail[]>([])
    const [fDepartments, setFDepartments] = useState<IDepartmentDetail[]>([])
    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [fSuppliers, setFSuppliers] = useState<ISupplier[]>([])
    const [projects, setProjects] = useState<IProjectDetail[]>([])
    const [fProjects, setFProjects] = useState<IProjectDetail[]>([])
    const [admins, setAdmins] = useState<IUser[]>([])
    const [coordinators, setCoordinators] = useState<IUser[]>([])

    const [fundcodes, setFundcodes] = useState<Fundcode[]>([])
    const [fFundcodes, setFFundcodes] = useState<Fundcode[]>([])
    const [activeTab, setActiveTab] = useState<number>(defaultTab)
    const [activeForm, setActiveForm] = useState<number>(0)

    const [open, setOpen] = useState<boolean>(false)

    const showNotifications = () => {
        navigate(`/notifications/${userData?.id}`)
    }
    const handleClickNew = () => {
        setActiveForm(activeTab)
        setOpen(true)
    }

    const getDepartments = async () => {
        const axios = await apiClient.getDepartments();
        if (axios.data) {
            setDepartments(axios.data.departments)
            setFDepartments(axios.data.departments)
        }
    }
    const getProjects = async () => {
        const axios = await apiClient.getAllProjects();
        if (axios.data) {
            setProjects(axios.data.projects)
            setFProjects(axios.data.projects)
        }
    }
    const handleTabSelect = (id: number) => {
        setActiveTab(id)
    }
    const handleFilter = (keyword: string) => {
        if (keyword && keyword.length > 0) {
            switch (activeTab) {
                case 1:
                    setFDepartments(departments.filter((d: IDepartmentDetail) => d.code.toLowerCase().includes(keyword) ||
                        d.description.toLowerCase().includes(keyword) || d.region.toLowerCase().includes(keyword) ||
                        d.nameOfAdmin?.toLocaleLowerCase().includes(keyword)));

                    break;
                case 2:
                    setFProjects(projects.filter((d: IProjectDetail) => d.code.toLowerCase().includes(keyword) ||
                        d.name.toLowerCase().includes(keyword) || d.donor?.toLowerCase().includes(keyword) || d.nameOfCoordinator?.toLowerCase().includes(keyword) ||
                        d.department_detail?.code?.toLowerCase().includes(keyword)));
                    break;
                case 3:
                    setFFundcodes(fundcodes.filter((d: Fundcode) => d.code.toLowerCase().includes(keyword) || d.project.toLowerCase().includes(keyword)));
                    break;
                case 4:
                    setFSuppliers(suppliers.filter((d: ISupplier) => d.name.toLowerCase().includes(keyword) ||
                        d.region.toLowerCase().includes(keyword) || d.service?.toLowerCase().includes(keyword)));
                    break;
            }

        }
        else {
            switch (activeTab) {
                case 1:
                    setFDepartments(departments);
                    break;
                case 2:
                    setFProjects(projects);
                    break;
                case 3:
                    setFFundcodes(fundcodes)
                    break;
                case 4:
                    setFSuppliers(suppliers)
            }
        }
    }
    const getFundcodes = async () => {
        const axios = await apiClient.getActiveFundcodes();
        if (axios.data) {
            setFundcodes(axios.data.fundcodes)
            setFFundcodes(axios.data.fundcodes)
        }
    }
    const loadUsers = async () => {
        const users = await apiClient.getActiveUsers();
        setAdmins(users.filter((u: IUser) => u.position == USER_POSITION_ID.ADMIN))
        setCoordinators(users.filter((u: IUser) => u.position == USER_POSITION_ID.COORDINATOR))
    }
    const getSuppliers = async () => {
        const suppliers = await apiClient.getAllSuppliers();
        setSuppliers(suppliers.data)
        setFSuppliers(suppliers.data)
    }
    const submitDepartmentForm = async (data: any) => {
        const axios = await apiClient.createDepartment(data)
        console.log("🚀 ~ submitDepartmentForm ~ axios:", axios)
        toast.info(axios.data.response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setOpen(false);
        getDepartments()
    }
    const submitProjectForm = async (data: any) => {
        const axios = await apiClient.createProject(data)
        toast.info(axios.data.response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setOpen(false);
        getProjects()
    }
    const submitFundcodeForm = async (data: any) => {
        const axios = await apiClient.createFundcode(data)
        toast.info(axios.data.response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setOpen(false);
        getFundcodes()
    }
    const submitSupplierForm = async (data: any) => {

        const axios = await apiClient.createSupplier(data)
        const message = axios.status == 201 ? axios.data.response?.message : "Oops! Something went wrong. Please contact support"
        toast.info(message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
        setOpen(false);
        getSuppliers()

    }
    useEffect(() => {
        if (isAuthenticated()) {
            if (isAdmin(userData)) {
                getDepartments();
                getProjects();
                getFundcodes();
                getSuppliers()
                loadUsers();
                setActiveTab(activeTab ? activeTab :1)
            }
            else setActiveTab(5)
        }
        else navigate('/login', { state: { targetPath: target } })
    }, [activeTab])

    return (<>
        <div className="w-full flex justify-end items-center bg-gray-100 pe-4">
           <TopStrip user={userData}/>
        </div>
        <ToastContainer />
        <main className="w-full flex bg-white min-h-full">

            <div className="md:w-2/12 flex flex-col items-start text-center justify-center">
                <Sidebar notificationCount={notifications.length} user={userData} active={6} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 f
            </div>
            {open && activeForm == 1 ? <DepartmentForm onSubmit={submitDepartmentForm} state={open} onClose={() => { setOpen(false) }} admins={admins} /> : null}
            {open && activeForm == 2 ? <ProjectForm onSubmit={submitProjectForm} state={open} onClose={() => { setOpen(false) }} coordinators={coordinators} departments={departments} /> : null}
            {open && activeForm == 3 ? <FundcodeForm onSubmit={submitFundcodeForm} state={open} onClose={() => { setOpen(false) }} projects={projects} /> : null}
            {open && activeForm == 4 ? <SupplierForm onSubmit={submitSupplierForm} state={open} onClose={() => { setOpen(false) }} /> : null}
            <div className="md:w-9/12 mx-auto space-y-4 mt-2">

                <div className="space-x-2 w-full my-2 pb-2 items-center justify-between">
                    <div className="w-full flex items-baseline justify-start border-b-2">
                        {tabs.map((s: any) => {
                            return <Tab key={s.id} title={s.title} onSelect={handleTabSelect} id={s.id} active={activeTab == s.id} />
                        })}
                    </div>
                    {activeTab < 5 ? <>
                        <div className="w-full flex items-center justify-between pt-2">
                            <SearchBox onChange={handleFilter} /> <button className="bg-primaryLight text-white" onClick={handleClickNew}>New</button>
                        </div>
                        <div className="w-full items-center justify-between pt-2">
                            {activeTab == 1 ? <DepartmentTable data={fDepartments} /> : null}
                            {activeTab == 2 ? <ProjectTable data={fProjects} /> : null}
                            {activeTab == 3 ? <FundcodeTable data={fFundcodes} /> : null}
                            {activeTab == 4 ? <SupplierTable data={fSuppliers} /> : null}
                        </div>
                    </> : null}
                    {activeTab == 5 ? <AccountSettings />:null}
                </div>
            </div>

        </main ></>
    );
}
export default Settings