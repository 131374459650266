import { CareLogoImage } from "../../components/image-assets";
import Image from "../../components/image";
import { dateFormat, generateFuelOrderNumber } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";


const FuelOrder = (props: any) => {
    const location = useLocation()
    const request = props.data ? props.data : location.state?.data;
    const navigate = useNavigate()

    const generatePDF = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const element = document.getElementById('fuel-order-container');
        if (!element) {
            throw new Error(`Element with id fuel-order-container not found`);
        }
        const canvas = await html2canvas(element, { scale: 1 });
        const data = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: [129, 70],
        });
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        const fileName = generateFuelOrderNumber(request)
        pdf.save(`${fileName}.pdf`);
    };

    return (<>
        <div className="w-full mx-auto pt-4" id="fuel-order-container">
            <div className="w-full space-y-3 mt-4">
                <Image src={CareLogoImage} className="w-1/12 mx-auto" />
                <p className="text-2xl text-center">Fuel Order</p>
                <p className="text-center">{DateTime.fromMillis(Date.now()).toLocaleString(DateTime.DATETIME_FULL)}</p>
            </div>
            <div className="md:w-8/12 flex justify-center py-6 mx-auto border mt-6">
                <div className="ms-5 md:w-4/12">
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Fuel Order No </label>
                        <p className="text-1xl">{generateFuelOrderNumber(request)}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Required Date </label>
                        <p className="text-1xl">{dateFormat(request.start_time)}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Fuel Quantity (Lts) </label>
                        <p className="text-1xl">{request.a_amount ? request.a_amount : request.amount}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Price (TSH) </label>
                        <p className="text-1xl">{request.a_price ? request.a_price : request.price}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Cost of Fuel (TSH) </label>
                        {request.a_amount ? <p className="text-1xl">{request.a_amount * Number(request.a_price)}</p>:<p className="text-1xl">{request.amount * Number(request.price)}</p>}
                        
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Supplier </label>
                        <p className="text-1xl">{`${request.supplier_name}`}</p>
                    </div>


                </div>
                <div className="ms-5 md:w-4/12">

                    <div className="text-start mb-4">
                        <label className="text-gray-500">Vehilce </label>
                        <p className="text-1xl">{`${request.vehicle}`}</p>
                    </div>

                    <div className="text-start mb-4">
                        <label className="text-gray-500">Mileage (Km) </label>
                        <p className="text-1xl">{`${request.mileage}`}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Last Refuel Mileage (Km) </label>
                        <p className="text-1xl">{`${request.last_request?.a_mileage}`}</p>
                    </div>
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Distance Covered (Km) </label>
                        <p className="text-1xl">{`${request.mileage - (request.last_request ? request.last_request.a_mileage : request.mileage)}`}</p>
                    </div>





                </div>
                <div className="ms-5 md:w-4/12">
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Project </label>
                        <p className="text-1xl">{`${request.project}`}</p>
                    </div>
                    <div className="text-start mb-4 ">
                        <label className="text-gray-500">Fund Code </label>
                        <p className="text-1xl">{`${request.fundcode}`}</p>
                    </div>
                    <div className="text-start mb-4 ">
                        <label className="text-gray-500">Account</label>
                        <p className="text-1xl">{`${request.account}`}</p>
                    </div>
                    <div className="text-start mb-4 ">
                        <label className="text-gray-500">Source</label>
                        <p className="text-1xl">{`${request.source}`}</p>
                    </div>
                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                        <label className="text-gray-500">Category </label>
                        <p className="text-1xl">{`${request.category}`}</p>
                    </div>
                    <div className="text-start mb-4  w-6/12 md:w-3/12">
                        <label className="text-gray-500">Activity </label>
                        <p className="text-1xl">{`${request.activity}`}</p>
                    </div>
                </div>
            </div>
            <div className="md:w-8/12  flex justify-start items-start mt-5 py-4 px-2 flex-wrap border mx-auto space-x-4">

                <div className="text-start mb-4  w-6/12 md:w-3/12">
                    <label className="text-gray-500">Requested by </label>
                    <p className="text-1xl">{`${request.nameOfRequestor}`}</p>
                    <input type="text" className="form-control my-2" />
                </div>

                <div className="text-start mb-4  w-6/12 md:w-3/12">
                    <label className="text-gray-500">Approved by </label>
                    <p className="text-1xl">{request.nameOfApprover}</p>
                    <input type="text" className="form-control my-2" />
                </div>
                <div className="text-start mb-4  w-6/12 md:w-3/12">
                    <label className="text-gray-500">Authorized by </label>
                    <p className="text-1xl">{request.nameOfAttender}</p>
                    <input type="text" className="form-control my-2" />
                </div>
                <div className="md:w-8/12 flex-column items-start justify-center mt-5 mx-auto">
                    <div className="text-start mb-4">
                        <label className="text-gray-500">Admin's Remarks </label>
                        <p className="text-sm">{request.remark1 ? request.remark1 : 'None'}</p>
                    </div>


                </div>
            </div>
        </div>
        <div className="md:w-8/12 flex justify-between items-center space-x-5 mt-1 mx-auto">
                <button className="bg-blue-500" onClick={generatePDF}>Download</button>
                <button type="button" className="bg-transparent border border-accent text-accent p-2 text-center" onClick={() => { navigate('/requests') }}>Back</button>
            </div>
        </>
    )
}
export default FuelOrder;